<template>
    <div>
        <div class="box">
            <div class="user_info">
                <img v-if="!customerInfo.avatar" class="user_avt" src="~@/assets/img/avt.png" alt="">
                <img v-else class="user_avt" :src="customerInfo.avatar" alt="">
                <div class="user_r">
                    <p class="user_name">
                        {{customerInfo.name}}
                        &nbsp;
                        <img class="user_sex" v-show="customerInfo.gender == 2" src="@/assets/img/female.svg" alt="女">
                        <img class="user_sex" v-show="customerInfo.gender == 1" src="@/assets/img/male.svg" alt="男">
                    </p>
                    <p class="user_info_type">{{customerInfo.type == 1?'@微信':'@企业微信'}}</p>
                </div>
            </div>

            <div class="user_info_list">
                <div class="user_info_item">
                    <p class="user_info_label">来源</p>
                    <p class="user_info_value">
                        {{source}}
                    </p>
                </div>

                <div class="user_info_item">
                    <p class="user_info_label">添加时间</p>
                    <p class="user_info_value">
                        {{customerInfo.createtime}}
                    </p>
                </div>

                <div class="user_info_item">
                    <p class="user_info_label">手机</p>
                    <p class="user_info_value">
                        {{customerInfo.remarkMobiles?customerInfo.remarkMobiles[0]:''}}
                    </p>
                </div>
                <div class="user_info_item">
                    <p class="user_info_label">加入群聊</p>
                    <p class="user_info_value" @click="showGroupList">
                        {{customerInfo.addCustomerGroupchatNum}}个
                    </p>
                </div>
                <div class="user_info_item">
                    <p class="user_info_label">已加客服</p>
                    <p class="user_info_value" @click="showCustomerList">
                        {{customerInfo.addCustomerNum}}名
                    </p>
                </div>


                <div class="user_info_item">
                    <p class="user_info_label">描述</p>
                    <p class="user_info_value">
                        {{customerInfo.remarkMobiles?customerInfo.remarkMobiles[0]:''}}
                    </p>
                </div>
            </div>

        </div>

        <!-- 已加客服弹窗 -->
        <van-popup v-model="isShowCustomerList" :round="true">
            <div>
                <p class="area_title">已加客服</p>
                <div class="from_container list_container">
                    <div v-for="(item,ind) in customerInfo.addCustomerLists" :key="ind" class="user_info">
                        <van-image
                            round
                            width="50"
                            height="50"
                            :src="item.avatar"
                        />
                        <div class="user_r">
                            <p class="user_name">
                                {{item.name}}
                                &nbsp;
                                 <img class="user_sex" v-show="item.gender == 2" src="@/assets/img/female.svg" alt="女">
                                 <img class="user_sex" v-show="item.gender == 1" src="@/assets/img/male.svg" alt="男">
                            </p>
                            <p class="department-name">{{item.deptName}}</p>
                        </div>
                    </div>
                    <van-empty v-if="customerInfo.addCustomerLists == ''"   description="暂无数据" />
                </div>
            </div>
        </van-popup>
        <!-- 已加客服弹窗 -->


        <!-- 已加入群聊弹窗 -->
        <van-popup v-model="isShowGroupList" :round="true">
            <div class="group-chat">
                <p class="area_title">加入的群聊</p>
                <div class="from_container list_container">
                    <div class="customer_list_item" v-for="(item,index) in customerInfo.addCustomerGroupchatLists" :key="index">
                        <p class="customer_item_info ">
                            <span class="customer_item_outer text_one_line_overflow_hide">{{item.name}}</span>
                            <span class="customer_item_fixed">({{item.num}}个)</span>
                        </p>
                        <p class="customer_item_info text_one_line_overflow_hide" style="display: block;">
                            群主：{{item.ownerWechatName}}
                        </p>
                    </div>
                    <van-empty v-if="customerInfo.addCustomerGroupchatLists == ''"   description="暂无数据" />
                </div>
                
            </div>
        </van-popup>
        <!-- 已加入群聊弹窗 -->
    </div>
</template>

<script>
    export default {
        props: ['customerInfo'],
        data() {
            return {
                isShowGroupList: false, // 是否展示已加入群聊弹窗
                isShowCustomerList: false, // 是否展示已加客服弹窗
            }
        },
        computed: {
            //根据状态返回来源
            source() {
                switch (this.customerInfo.addWay) {
                    case 0:
                        return '未知来源'
                    case 1:
                        return '扫描二维码'
                    case 2:
                        return '搜索手机号'
                    case 3:
                        return '名片分享'
                    case 4:
                        return '群聊'
                    case 5:
                        return '手机通讯录'
                    case 6:
                        return '微信联系人'
                    case 8:
                        return '安装第三方应用时自动添加的客服人员'
                    case 9:
                        return '搜索邮箱'
                    case 10:
                        return '视频号添加'
                    case 201:
                        return '内部成员共享'
                    case 202:
                        return '管理员/负责人分配'
                    default:
                        return ''
                }
            }
        },
        methods: {
            // 查看群列表
            showGroupList() {
                this.isShowGroupList = true;
            },

            // 查看客服列表
            showCustomerList() {
                this.isShowCustomerList = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
 
        .user_info {
            display: flex;
            align-items: center;
        }

        .user_avt {
            width: 116px;
            height: 116px;
            border-radius: 50%;
        }

        .user_r {
            margin-left: 38px;
        }

        .user_name {
            color: #575F76;
            font-size: 26px;
            margin-bottom: 14px;
            font-weight: bold;
            word-break: break-word;
            display: flex;
            align-items: center;
        }

        .user_sex {
            width: 34px;
            height: 34px;
        }

        .user_info_type {
            font-size: 24px;
            color: #26c445;
        }

        .user_info_list {
            margin-top: 30px;
        }

        .user_info_item {
            display: flex;
            margin-bottom: 30px;
        }

        .user_info_label {
            width: 120px;
            color: #A6A6A6;
            font-size: 26px;
            margin-right: 42px;
        }

        .user_info_value {
            color: #575F76;
            font-size: 26px;
            flex-grow: 1;
            word-break: break-all;
            // line-height: 46px;
        }
    


    .list_container {
        width: 640px;
        box-sizing: border-box;
        height: 70vh;
        overflow: auto;
    }

    .list_container .user_info {
        margin-bottom: 30px;
    }

    .list_container .user_info:last-child {
        margin-bottom: 0;
    }

    .customer_item_info {
        font-size: 28px;
        display: flex;
        color: #575F76;
        margin-top: 20px;
    }

    .customer_item_outer {
        // width: 300px;
        font-size: 28px;
        
    }

    .customer_item_fixed {
        flex-shrink: 0;
    }


    .user_info_type {
        font-size: 25px;
        color: #26c445;
    }

    .from_container{
        padding-top: 0;
    }
    .customer_list_item{
        margin-bottom: 35px;
    }
    .department-name{
        font-size: 26px;
        color: #575F76;
    }
</style>