<template>
    <div>
        <div class="box">
            <p class="title">标签</p>
            <div class="label_list">
                <div v-for="(item,index) in showTagList" :key="index" class="label_item_box">
                    <div @click="delLabel(index)" class="del_label">+</div>
                    <div class="label_item">{{item.tagContent}}</div>
                </div>
                <div @click="isShowSelectTag = true" class="add_label">
                    +
                </div>
            </div>
        </div>


        <van-popup v-model="isShowSelectTag" round position="bottom">
            <!-- v-model="cascaderValue" -->
            <van-cascader title="请选择标签" v-model="cascaderValue" :field-names="{ text: 'label', value: 'value', children: 'children' }"
                :options="tagList" @close="isShowSelectTag = false" @finish="selectTapFinish" />
        </van-popup>

        <!-- 删除标签 -->
        <handel-tip v-model="isShowDelLabel" @confirm="delTag()" @cancel="isShowDelLabel = false"
            content-text="确定要删除吗？"></handel-tip>
        <!-- 删除标签 -->

    </div>
</template>

<script>
    
    export default {
        props:['tagList','showTagList'],
        data() {
            return {
                cascaderValue:'',//级联选择标签的值
                isShowSelectTag: false, // 是否展示
                isShowDelLabel: false, // 是否展示删除标签的弹窗
                selectTapOption: [{
                    "value": 4,
                    "label": "行业",
                    "children": [{
                        "value": 11,
                        "label": "互联网/软件/信息技术",
                        "isDefault": 1
                    }]

                }],

                delIndex:'',//删除标签存储的索引值
            }
        },
        methods: {
            //删除标签
            delLabel(index) {
                this.isShowDelLabel = true;
                this.delIndex = index
            },

            //删除标签（自定义事件）
            delTag(){
                this.$emit('delTag',this.delIndex)
            },

            //选择标签
            selectTapFinish(value, selectedOptions, tabIndex) {
                console.log(value.selectedOptions);
                //把id从数组结构    
                let [tagGroupId,tagId] = value.selectedOptions
                let obj = {
                    tagGroupId:tagGroupId.value,
                    tagId:tagId.value,
                    tagContent:tagId.label,
                    isDefault:1
                }

                this.$emit('upDataTages',obj)
                setTimeout(()=>{
                    this.isShowSelectTag = false
                    this.cascaderValue = ''
                },150)
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .label_list {
        display: flex;
        flex-wrap: wrap;
    }

    .label_item_box {
        position: relative;
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .label_item {
        background-color: #F73B42;
        border-radius: 5px;
        font-size: 25.46px;
        font-weight: 400;
        color: #ffffff;
        height: 55px;
        line-height: 55px;
        padding: 0 25px;
    }

    .del_label {
        color: #858da1;
        width: 28px;
        height: 28px;
        background-color: #ffffff;
        box-shadow: 0px 5px 9px 0px rgba(154, 154, 154, 0.50);
        border-radius: 50%;
        position: absolute;
        top: -14px;
        right: -14px;
        font-size: 16px;
        text-align: center;
        line-height: 28px;
        transform: rotate(45deg);
    }

    .add_label {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 5px solid #f73b42;
        box-sizing: border-box;
        font-size: 28px;
        text-align: center;
        line-height: 24px;
        color: #f73b42;
        font-weight: bold;
        margin-top: 12px;
    }
</style>