import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./routerMapping";
import { getLoginFlag, setDocumentTitle } from "../utils/index";
import { wxJsdkAuthorize } from "../utils/wxUtil";
import config from "../config/config";
import store from '../store';
import { Toast } from 'vant';

import { createSignature, createAgentSignature } from "../request/api";
import { EVENT_BUS_WORK_WX_AUTH_SUCCESS } from "../constant/eventBusConstant";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

// 前置拦截
router.beforeEach((to, from, next) => {
  let loginFlag = getLoginFlag();

  // 设置页面title
  setDocumentTitle(to.meta.title);

  if(!loginFlag && config.environment != 2 && window.location.href.indexOf("192.168.") == -1){
    // 登录状态不存在去授权
    wxJsdkAuthorize();
  } else {
    next();
  }

});

// 后置拦截
router.afterEach(async(to) => {
  console.log(to);

  // 获取签名
  let signatureResult = await createSignature({corpId: store.state.corpId});
  let agentSignatureResult = await createAgentSignature({corpId: store.state.corpId});
console.log(3333)
  if(signatureResult.status == 1){
  
    // 注入api权限
    window.wx.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      appId:  store.state.corpId, // 必填，企业微信的corpID
      timestamp: signatureResult.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: signatureResult.data.nonceStr, // 必填，生成签名的随机串
      signature: signatureResult.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: ['getContext'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  
    });

    // 检查api是否可用
    window.wx.checkJsApi({
      jsApiList: ['getContext'], // 需要检测的JS接口列表
      success: function() {
          
      }
    });

    window.wx.ready(function(){
      // 注入api权限
      window.wx.agentConfig({
          corpid: store.state.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: Number(store.state.agentid), // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: agentSignatureResult.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: agentSignatureResult.data.nonceStr, // 必填，生成签名的随机串
          signature: agentSignatureResult.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['sendChatMessage','getCurExternalContact','getContext'], //必填，传入需要使用的接口名称
          success: function() {

            // 获取当前场景
            window.wx.invoke('getContext', {}, function(res){
              store.commitFun("appContext", res.entry);
              // 通知页面可以调用相应的wxapi了
              Vue.prototype.$EventBus.$emit(EVENT_BUS_WORK_WX_AUTH_SUCCESS);
            });

          },
          fail: function() {
            console.log(arguments,"1");
            Toast("授权失败");
          }
      });

    });

    window.wx.error(function(){
      console.log(arguments,"2");

      Toast("授权失败");
    });

  } else {
    Toast(signatureResult.msg);
  }

});

export default router;