import request from "./request";
import config from "../config/config";

let REQUEST_BASEURL = config.requestBaseUrl;

// 授权
export let auth = ({code, appId}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/codeAuth",{code, appId});

// 获取签名
export let createSignature = ({corpId}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/createSignature",{corpId, url: window.location.href });

// 获取Agent签名
export let createAgentSignature = ({corpId}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/createAgentSignature",{corpId, url: window.location.href });

// 上传企业微信素材
export let uploadQywxMedia = (data) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/uploadQywxMedia",data);


// 查询拉新数据
export let pullNewData = ({corpid, externalUserid, servicerUserid, companyRand}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/pullNewData",{corpid, externalUserid, servicerUserid, companyRand });

// 获取拓客拉新码（二维码）
export let getPullNewCode = ({
    corpid, externalUserid, servicerUserid, companyRand
}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getPullNewCode",{
    corpid, externalUserid, servicerUserid, companyRand
});

// 查询公共快捷回复列表
export let getPublicQuickReplyList = ({ companyRand, keywords }) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getPublicQuickReplyList",{ companyRand, keywords });

// 上传图片到oss接口
export let imageUpload = (data) => request(REQUEST_BASEURL + "/company/company/imageUpload", data);

// 快捷回复添加分组
export let saveCompanyWechatReplyGroup = ({ companyRand, corpid, userid, type, name }) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/saveCompanyWechatReplyGroup",{ companyRand, corpid, userid, type, name });

// 快捷回复添加回复
export let saveCompanyWechatReply = ({
    companyRand, corpid, userid, type, groupId, title, content, img 
}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/saveCompanyWechatReply",{ 
    companyRand, corpid, userid, type, groupId, title, content, img 
});

// 快捷回复获取分组列表
export let getGroupList = ({
    companyRand, corpid, userid, type
}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getGroupList",{ 
    companyRand, corpid, userid, type
});

// 快捷回复获取分组列表
export let getPersonQuickReplyList = ({
    companyRand, corpid, userid, content
}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getPersonQuickReplyList",{ 
    companyRand, corpid, userid, content
});

