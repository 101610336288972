//画像接口
import request from "./request";
import config from "../config/config";

let REQUEST_BASEURL = config.requestBaseUrl;

//获取企业微信客户详情
export const reqWechatCustomerDetails = ({companyRand, externalUserid,userId,corpId	}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getCompanyWechatExternalContact",{companyRand, externalUserid,userId,corpId});

//企业微信客户关联火烈云客户
export const relationCompanyWechatCustomer = ({rand, externalUserId,userId,corpId,customerId	}) => request(REQUEST_BASEURL + "/pay/customer/relationCompanyWechatCustomer",{rand, externalUserId,userId,corpId,customerId});

//保存企业微信客户信息标签
export const saveCompanyWechatCustomerTag = ({rand, externalUserId,userId,corpId,tagList	}) => request(REQUEST_BASEURL + "/pay/customer/saveCompanyWechatCustomerTag",{rand, externalUserId,userId,corpId,tagList});

// 修改客户备注信息
export const SetExternalContactRemark = ({companyRand, externalUserid,userId,corpId,remark,description,remarkMobiles	}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/SetExternalContactRemark",{companyRand, externalUserid,userId,corpId,remark,description,remarkMobiles});

//已关联企业微信客户取消关联
export const cancelRelationCompanyWechatCustomer = ({rand, externalUserId,userId,corpId	}) => request(REQUEST_BASEURL + "/pay/customer/cancelRelationCompanyWechatCustomer",{rand, externalUserId,userId,corpId});

//查询标签列表
export const getLabelList = ({companyRand}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getLabelList",{companyRand});

//查询火烈云已有客户列表
export const selectCompanyWechatGLCustomerList = ({rand,keyWord,groupId}) => request(REQUEST_BASEURL + "/pay/customer/selectCompanyWechatGLCustomerList",{rand,keyWord,groupId});

//查询火烈云已有客户分组列表
export const getCustomerGroupListforCompanyWechat = ({rand}) => request(REQUEST_BASEURL + "/pay/customer/getCustomerGroupListforCompanyWechat",{rand});

// 查询跟进记录列表
export const getCustomerRecordList = ({companyRand,corpid,userid, externalUserId}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getCustomerRecordList",{companyRand,corpid,userid,externalUserId});

//添加/编辑跟进记录
export const saveCustomerRecord = ({id,companyRand,corpid,userid,content,kfUserid,imgList}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/saveCustomerRecord",{id,companyRand,corpid,userid,content,kfUserid,imgList});

//编辑跟进记录回显信息
export const getCustomerRecordInfo = ({id}) => request(REQUEST_BASEURL + "/pay/companyWxThirdPartyAuth/getCustomerRecordInfo",{id});

//自定义企业微信客户信息保存
/*
  "rand":"OO6gkYcY",                //类型：String  必有字段  备注：企业rand
    "externalUserId":"wmXHBuEwAAqrBvzwRHmodqAUR-T_eV3A",                //类型：String  必有字段  备注：客户微信id
    "userId":"SunMing",                //类型：String  必有字段  备注：客服userid
    "corpId":"ww05178bc3c60fe5d3",                //类型：String  必有字段  备注：微信唯一标识
    "customerId":"null",                //类型：Mixed  可有字段  备注：关联火烈云客户id
    "contacts":"李四2",                //类型：String  可有字段  备注：联系人
    "customerName":"张三2",                //类型：String  可有字段  备注：客户名称
    "age":"22",                //类型：String  可有字段  备注：年龄
    "gender":"1",                //类型：String  可有字段  备注：性别（0女，1男，2未知）
    "phone":"13088888888",                //类型：String  可有字段  备注：手机
    "qq":"8888888",                //类型：String  可有字段  备注：qq
    "wechat":"zzzzz",                //类型：String  可有字段  备注：微信
    "vBlog":"weibo",                //类型：String  可有字段  备注：微博
    "address":"address",                //类型：String  可有字段  备注：地址
    "email":"email",                //类型：String  可有字段  备注：邮箱
    "starId":"1",                //类型：String  可有字段  备注：星级
    "remarks":"remark1"                //类型：String  可有字段  备注：备注
*/ 
export const saveCompanyWechatCustomer = ({
    rand,
    externalUserId,
    userId,
    corpId,
    customerId,
    contacts,
    customerName,
    age,
    gender,
    phone,
    qq,
    wechat,
    vBlog,
    address,
    email,
    starId,
    remarks
}) => request(REQUEST_BASEURL + "/pay/customer/saveCompanyWechatCustomer",{
    rand,
    externalUserId,
    userId,
    corpId,
    customerId,
    contacts,
    customerName,
    age,
    gender,
    phone,
    qq,
    wechat,
    vBlog,
    address,
    email,
    starId,
    remarks
});

// 自定义企业微信客户信息查询

export const getCustomerByExternalUserIdandRand = ({externalUserId,userId,corpId,rand}) => request(REQUEST_BASEURL + "/pay/customer/getCustomerByExternalUserIdandRand",{externalUserId,userId,corpId,rand});


