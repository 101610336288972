import customerPortraitPage from "../page/customerPortraitPage/index.vue";
import customerPortraitEdit from "../page/customerPortraitPage/editUser.vue";
import quickReplyPage from "../page/quickReplyPage/index.vue";
import pullNewLiveCodePage from "../page/pullNewLiveCodePage/index.vue";


export default [
  {
    path: '/customerPortrait',
    component: customerPortraitPage,
    meta: {
      title: '客户画像',
    },
  },
  {
    path: '/customerPortrait/eidt',
    component: customerPortraitEdit,
    meta: {
      title: '编辑客户信息',
    },
  },
  {
    path: '/quickReply',
    component: quickReplyPage,
    meta: {
      title: '快捷回复'
    }
  },
  {
    path: '/pullNewLiveCode',
    component: pullNewLiveCodePage,
    meta: {
      title: '拉新活码'
    }
  }
];
