import Vue from 'vue';
// 适配屏幕插件
import 'amfe-flexible';

import App from './App.vue';
import router from './router';
import store from './store';

// 公共css
import './assets/css/Normalize.css';
import './assets/css/public.css';

// vant 导入
import "../vanImport";

// 注册全局总线事件
Vue.prototype.$EventBus = new Vue();

Vue.config.productionTip = false;

// 刷新页面时保存vuex状态
if (sessionStorage.getItem("vuexStore")) {
  store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("vuexStore"))));
}
window.addEventListener("beforeunload",()=>{
  sessionStorage.setItem("vuexStore", JSON.stringify(store.state));
});
// 刷新页面时保存vuex状态

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
