import Vue from 'vue';
import { 
  Checkbox, CheckboxGroup,Rate,
  RadioGroup, Radio,
  Popup, Cascader,
  Steps, Step, Icon, dialog,
  Toast, List, Picker ,
  Image as VanImage,
  Loading, Empty ,
  Overlay
} from "vant";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Cascader);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Icon);
Vue.use(dialog);
Vue.use(Toast);
Vue.use(List);
Vue.use(Picker);
Vue.use(Rate);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(VanImage);
Vue.use(Overlay);

import HandelTip from "./src/components/handelTip";
Vue.component("handel-tip", HandelTip);
import PackageLoading from "./src/components/PackageLoading";
Vue.component("package-loading", PackageLoading);