import { auth } from "../request/api";
import config from "../config/config";
import { getLoginFlag, setLoginFlag, removeLoginFlag,  parseUrlPara } from "./index";
import { Toast } from 'vant';
import store from "../store/index";
import router from "../router/index";

// 微信授权
export async function wxJsdkAuthorize() {

	// 解析url上的所有参数
	let urlPara = parseUrlPara();
	
	// 获取登录标识
	let loginFlag = getLoginFlag();

	if(!loginFlag){
		// 未登录的情况
		let code = urlPara.code;
		console.log("code:",code);
		if(code){
			let result = await auth({code, appId: store.state.corpId});
			console.log("authResult:",result);
		
			if(result.status == 1){
				
				setLoginFlag(result.data.openUserId);
				
				store.commitFun("corpId", result.data.corpId);
				store.commitFun("openUserId", result.data.openUserId);
				store.commitFun("userId", result.data.userId);
				store.commitFun("deviceId", result.data.deviceId);
				store.commitFun("rand", result.data.rand);
				store.commitFun("agentid", result.data.agentid);
				router.push(window.location.pathname);
			} else {
				removeLoginFlag();
				Toast(result.msg);
			}
		} else {
		
			let appid = urlPara.appid;

			let authUrl = config.authUrl;
			authUrl = authUrl.replace("APPID", appid);
			store.commitFun("corpId", appid);
			
			sessionStorage.setItem("vuexStore", JSON.stringify(store.state));

			authUrl = authUrl.replace("REDIRECT_URI", encodeURIComponent(window.location.href));
			window.location.replace(authUrl);
		}

	}
	
}