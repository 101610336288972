const environment = 0;   // 0---使用测试的环境   1---使用正式的环境   2---本地环境

let config = {};

if(environment === 0){
	// 测试环境
	config = {
		requestBaseUrl: "https://hly.huolie.cc",
		environment,
		authUrl: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base#wechat_redirect", // 授权链接
	}
	
} else if(environment === 1){
	// 正式环境
	config = {
		requestBaseUrl: "http://qywx.free.aeert.com",
		environment,
		authUrl: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base#wechat_redirect", // 授权链接
	}
} else {
	// 本地环境
	config = {
		requestBaseUrl: "http://qywx.free.aeert.com",
		environment,
		authUrl: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base#wechat_redirect", // 授权链接
	}
}

export default config;