<template>
  <div class="page">
    <div class="box margin_bottom">

      <div class="quick_tab">
        <div class="quick_tab_box">
          <div @click="changeTab(0)" class="quick_tab_item" :class="{'quick_tab_item_active': activeTab == 0}">
            全部
          </div>
          <div @click="changeTab(1)" class="quick_tab_item" :class="{'quick_tab_item_active': activeTab == 1}">
            个人
          </div>
          <div @click="changeTab(2)" class="quick_tab_item" :class="{'quick_tab_item_active': activeTab == 2}">
            公共
          </div>
        </div>

        <div @click="changeSwitchBtn" class="search_btn_box" >
          <van-icon name="search" />
        </div>
      </div>

      <search v-if="searchSwitchState == 2" class="search_margin" v-model="publicQuickReplyListSearchkeywords" @search="search" placeholder="搜索常用语"></search>

      <div v-if="searchSwitchState == 1" class="group_list"> 
        <div class="group_item " v-for="(item) in groupList" :key="item.replyGroupId" 
          :class="{'group_item_active': item.replyGroupId == activeReplyGroupId}"
          @click="changeGroup(item.replyGroupId)"  
        >{{item.replyGroupName}}</div>
      </div>
      
      <div >
        <p class="quick_title">{{currentPageGroupName}}</p>
        <div class="quick_content_list">
          <template v-for="(item) in replyList">
            <div @click="quickReply(item)" :key="item.id" class="quick_content_item">
              <div class="text_one_line_overflow_hide quick_content_c">
                <span class="quick_reply_title">{{item.title}}</span> {{item.content}}
              </div>
              <img v-if="item.img" class="quick_content_img" :src="item.img" alt="">
            </div>
          </template>
          <van-empty v-if="replyList.length == 0" description="暂无数据" />

        </div>
      </div>
    
    </div>


    <div class="bottom_btn_list">
      <button @click="openAddqioclRelyArea()" class="bottom_btn_l">添加快捷回复</button>
      <button @click="openAddGropuArea()" class="bottom_btn_r">添加分组</button>
    </div>

    <!-- 添加快捷回复 -->
    <van-popup
      v-model="isShowAddqioclRelyArea"
      :round="true"
      :closeable="true"
      position="bottom"
    >
      <p class="area_title">添加快捷回复</p>
      <div class="from_container">
        <div class="from_item">
          <div class="from_title">添加位置</div>
          <div class="from_content">
            <van-radio-group @change="addQuickReplyTypeChange" v-model="addQuickReplyFromData.type" direction="horizontal">
              <van-radio name="1">个人快捷回复</van-radio>
              <van-radio name="2">公共快捷回复</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">回复分组</div>
          <div class="from_content">
            <div @click="isShowselectGroup = true" class="from_select">
              {{addQuickReplyFromData.groupId ? groupName : '请选择分组'}}
            </div>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">回复标题</div>
          <div class="from_content">
            <input maxlength="30" v-model="addQuickReplyFromData.title" type="text" class="from_input" placeholder="请输入回复标题">
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">回复内容</div>
          <div class="from_content">
            <textarea maxlength="300" v-model="addQuickReplyFromData.content" class="from_textarea" placeholder="请输入回复内容"></textarea>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">上传图片</div>
          <div class="from_content up_img_style">
            <input @change="upImageChange" ref="upFileInput" type="file" accept="image/*" style="display: none;">
            <div v-if="!addQuickReplyFromData.img" @click="upImage" class="up_img_btn"></div>
            <img v-else @click="upImage" :src="addQuickReplyFromData.img" class="up_img_perview" >
            <p class="up_img_desc">*可上传一张2M以内的png和jpg格式的图 片，建议尺寸100*100px</p>
          </div>
        </div>
        <div class="from_btn_list">
          <button class="while_btn">取消</button>
          <button @click="saveCompanyWechatReply" class="imp_btn">保存</button>
        </div>
      </div>
    </van-popup>
    <!-- 添加快捷回复 -->

    <!-- 添加快捷回复选择分组弹窗 -->
    <van-popup v-model="isShowselectGroup" round position="bottom">
      <van-picker
        :default-index="currentGroupIndex"
        show-toolbar
        title="请选择分组"
        :columns="addReplyGroupList"
        @confirm="onGroupConfirm"
        @cancel="isShowselectGroup = false"
      />
    </van-popup>
    <!-- 添加快捷回复选择分组弹窗 -->

    <!-- 添加快捷回复分组 -->
    <van-popup
      v-model="isShowAddGropuArea"
      :round="true"
      :closeable="true"
      position="bottom"
    >
      <p class="area_title">添加分组</p>
      <div class="from_container">
        <div class="from_item">
          <div class="from_title">添加位置</div>
          <div class="from_content">
            <van-radio-group v-model="companyWechatReplyGroupFromData.type" direction="horizontal">
              <van-radio name="1">个人快捷回复</van-radio>
              <van-radio name="2">公共快捷回复</van-radio>
            </van-radio-group>
          </div>
        </div>
         <div class="from_item">
          <div class="from_title">分组名称</div>
          <div class="from_content">
            <input maxlength="30" v-model="companyWechatReplyGroupFromData.name" type="text" placeholder="请输入分组名称" class="from_input">
          </div>
        </div>
        <div class="from_btn_list">
          <button @click="isShowAddGropuArea = false" class="while_btn">取消</button>
          <button @click="saveCompanyWechatReplyGroup" class="imp_btn">保存</button>
        </div>
      </div>
    </van-popup>
    <!-- 添加快捷回复分组 -->

    <package-loading :is-show="requestLock"></package-loading>

  </div>
</template>

<script>
import search from "../../components/commonSearch.vue";
import { getPublicQuickReplyList, getPersonQuickReplyList, imageUpload, uploadQywxMedia, saveCompanyWechatReplyGroup, getGroupList, saveCompanyWechatReply } from "../../request/api";
import { EVENT_BUS_WORK_WX_AUTH_SUCCESS } from "../../constant/eventBusConstant";
import { mapState } from "vuex";
import { getBase64Image, dataURLtoFile } from "@/utils/index";

export default {
  data(){
    return {
      activeTab: 0, // 当前激活的选项卡
      isShowAddGropuArea: false,
      isShowAddqioclRelyArea: false,
      publicQuickReplyListSearchkeywords: "", // 回复列表搜索关键字
      groupList: [], // 分组list
      activeReplyGroupId: "", // 当前选中的分组id
      replyList: [], // 回复List
      searchSwitchState: 1, // 1 -- 使用分组搜索 2 -- 使用筛选搜索
      requestLock: false,

      addQuickReplyFromData: {
        type: "1", 
        groupId: "", 
        title: "", 
        content: "", 
        img: "" 
      }, // 添加快捷回复表单
      companyWechatReplyGroupFromData:{
        type: "1", // 1 -- 个人 2 --公共
        name: "", // 分组名称
      }, // 添加快捷回复分组表单
      addReplyGroupList:[], // 添加回复用的 分组列表
      isShowselectGroup: false,
      
    }
  },
  created(){
    this.$EventBus.$on(EVENT_BUS_WORK_WX_AUTH_SUCCESS, this.authSuccess);
  },
  computed: {
    ...mapState(['rand','corpId','userId']),
    // 添加时选中的分组名称
    groupName(){
      let result = this.addReplyGroupList.find((data) => {
        return data.value == this.addQuickReplyFromData.groupId;
      });

      if(!result){
        return '';
      } else {
        return result.text;
      }
    },
    // 添加时当前分组的索引
    currentGroupIndex(){
      let index = this.addReplyGroupList.findIndex((data) => {
        return data.value == this.addQuickReplyFromData.groupId;
      });

      if(index == -1){
        index = 0;
      }

      return index;
    },

    currentPageGroupName(){
      let result = this.groupList.find((data) => {
        return data.replyGroupId == this.activeReplyGroupId;
      });

      if(result){
        return result.replyGroupName;
      } else {
        return "全部分组";
      }
    }
    
  },
  methods:{
    // 授权成功
    async authSuccess(){
      this.getDataList();
    },

    // 添加分组时
    onGroupConfirm(data){
      this.addQuickReplyFromData.groupId = data.value;
      this.isShowselectGroup = false;
    },

    // 查询分组数据
    async getDataList(){

      if(this.requestLock){
        return 
      }
      
      this.requestLock = true;
      
      this.groupList = [];
      this.replyList = [];


      if(this.activeTab == 0){
        await this.getPublicQuickReplyList();
        await this.getPersonQuickReplyList();
        this.requestLock = false;
        return
      }

      if(this.activeTab == 1){
        await this.getPersonQuickReplyList();
        this.requestLock = false;
        return
      }
     
      if(this.activeTab == 2){
        await this.getPublicQuickReplyList();
        this.requestLock = false;
        return
      }


    },

    // 打开添加分组弹窗
    openAddGropuArea(){
      this.companyWechatReplyGroupFromData.type = "1";
      this.companyWechatReplyGroupFromData.name = "";

      this.isShowAddGropuArea = true;
    },

    // 打开添加回复弹窗
    openAddqioclRelyArea(){
      this.addQuickReplyFromData.type = "1";
      this.addQuickReplyFromData.groupId = "";
      this.addQuickReplyFromData.title = "";
      this.addQuickReplyFromData.content = "";
      this.addQuickReplyFromData.img = "";

      this.isShowAddqioclRelyArea = true;
      this.getGroupList();
    },

    // 查询分组列表
    async getGroupList(){
      let result = await getGroupList({
        companyRand: this.rand, 
        corpid: this.corpId, 
        userid: this.userId,
        type: this.addQuickReplyFromData.type
      });

      this.addReplyGroupList = result.data.map((data) => {
        return {
          value: data.id || data.replyGroupId,
          text: data.name || data.replyGroupName,
        }
      });
    },

    // 回复类型变更
    addQuickReplyTypeChange(){
      this.getGroupList();
    },

    // 保存快捷回复
    async saveCompanyWechatReply(){
      if(this.requestLock){
        return
      }

      this.requestLock = true;

      try{

        let fromData = {
          ...this.addQuickReplyFromData,
          companyRand: this.rand, 
          corpid: this.corpId, 
          userid: this.userId
        }

        let result = await saveCompanyWechatReply(fromData);

        if(result.status == 1){
          this.isShowAddqioclRelyArea = false;
        }

        this.$toast(result.msg);

      } finally {
        this.requestLock = false;
      }

      // 拉取列表
      this.getDataList();
    },

    // 保存快捷回复分组
    async saveCompanyWechatReplyGroup(){
      if(this.requestLock){
        return
      }
      this.requestLock = true;

      try{

        let fromData = {
          ...this.companyWechatReplyGroupFromData,
          companyRand: this.rand, 
          corpid: this.corpId, 
          userid: this.userId
        }
        
        let result = await saveCompanyWechatReplyGroup(fromData);

        if(result.status == 1){
          this.isShowAddGropuArea = false;
        }

        this.$toast(result.msg);

      } finally {
        this.requestLock = false;
      }

      // 拉取列表
      this.getDataList();
    },

    // 点击上传图片
    upImage(){
      this.$refs.upFileInput.click();
    },

    // 选择图片后
    async upImageChange(e){
      if(this.requestLock){
        return
      }
      this.requestLock = true;

      try{

        let file = this.$refs.upFileInput.files[0];

        let filetypes = ["JPG", "PNG", "JPEG"];
        let strArr = file.name.split(".");
        let lastName = strArr[strArr.length - 1].toUpperCase();
        if(filetypes.indexOf(lastName) == -1){
            this.$toast("请选择正确的文件类型");
            this.requestLock = false;
            this.$refs.upFileInput.value = "";
            return 
        }
        
        if (file.size >= 2 * 1000 * 1000) {
            this.$toast("图片大小不能超过2M，请重新选择");
            this.requestLock = false;
            this.$refs.upFileInput.value = "";
            return 
        }

        let fromData = new FormData();
        fromData.append("file", file);
        
        let result = await imageUpload(fromData);

        if(result.status == 1){
          this.addQuickReplyFromData.img = result.data.url;
        } else {
          this.$toast(result.msg);
        }
        this.$refs.upFileInput.value = "";
      } finally {
        this.requestLock = false;
      }

    },

    // 分组搜索
    search(){
      this.activeReplyGroupId = "";
      this.getDataList();
    },

    // 分组筛选
    changeGroup(groupId){
      if(groupId == this.activeReplyGroupId){
        this.activeReplyGroupId = "";
        this.getDataList();
        return 
      }

      this.activeReplyGroupId = groupId;
      this.getDataList();
    },

    // 切换搜索方式
    changeSwitchBtn(){
      this.publicQuickReplyListSearchkeywords = "";

      this.getDataList();
      if(this.searchSwitchState == 1) {
        this.searchSwitchState = 2;
        return 
      }
      if(this.searchSwitchState == 2){
        this.searchSwitchState = 1;
        return 
      }

    },

    // 查询公共快捷回复列表
    async getPublicQuickReplyList(){
      let result = await getPublicQuickReplyList({
        // companyRand: "OO6gkYcY",
        companyRand: this.rand,
        keywords: this.publicQuickReplyListSearchkeywords,
      });

      if(result.status == 1){

        if(!this.publicQuickReplyListSearchkeywords){
           for(let index in result.data.groupList){
            let item = result.data.groupList[index];
            this.groupList.push({
              replyGroupId: item.replyGroupId,
              replyGroupName: item.replyGroupName,
            });

           let arr = item.tdFastCommonReply.map((data) =>{
              return {
               groupId: data.replyGroupId,
               content: data.replyinfo,
               img: data.replyImg,
               title: data.title
              }
            });

            if(!this.activeReplyGroupId){
     
              this.replyList.push(...arr);
            } else {
              if(item.replyGroupId == this.activeReplyGroupId){
                this.replyList.push(...arr);
              }
            }
          }
        } else {
          
          if(result.data.replyList){
            this.replyList = result.data.replyList.map((data) => {
               return {
                groupId: "",
                content: data.replyinfo,
                img: data.replyImg,
                title: data.title
               }
             });
          }
         
        }
       
      } else {
         this.$toast(result.msg);
      }
     
    },

    async getPersonQuickReplyList(){
      let result = await getPersonQuickReplyList({
        companyRand: this.rand,
        corpid: this.corpId, 
        userid: this.userId,
        content: this.publicQuickReplyListSearchkeywords
      });

      if(result.status == 1){

        if(!this.publicQuickReplyListSearchkeywords){
          for(let index in result.data){
            let item = result.data[index];
            this.groupList.push({
              replyGroupName: item.name,
              replyGroupId: item.id
            });

            if(!this.activeReplyGroupId){
              this.replyList.push(...item.replyList);
            } else {
              if(item.id == this.activeReplyGroupId){
                this.replyList.push(...item.replyList);
              }
            }
          }
        } else {
          if(result.data){
            this.replyList.push(...result.data);
          }
        }
        
      } else {
         this.$toast(result.msg);
      }
    },

    // 获取媒体id
    async getMediaId(link){
      // 转base64
      let base64 = await getBase64Image(link + "?time=" + new Date().valueOf());
      // 转文件流
      let file = dataURLtoFile(base64, new Date().valueOf());

      let fromData = new FormData();
      fromData.append("corpid", this.corpId);
      fromData.append("file", file);
      fromData.append("companyRand", this.rand);

      let uploadQywxMediaResult = await uploadQywxMedia(fromData)
    
      if(uploadQywxMediaResult.status == 1){

        return uploadQywxMediaResult.data.mediaId;

      } else {
        return "";
      }
    },

    // 发送消息
    sendChatMessage(option){
      return new Promise((resolve) => {
        window.wx.invoke('sendChatMessage', option, (result) => {
          resolve(result);
        });
      });
    },

    // 快捷回复
    async quickReply(data){
      let replyText = data.content;

      if(this.requestLock){
        return 
      }
      this.requestLock = true;

      if(replyText){
        await this.sendChatMessage({
          msgtype: "text",
          enterChat: true,
          text: {
            content:replyText, 
          },
        });
      }
      
      if(data.img){
        let mediaId = await this.getMediaId(data.img);
        await this.sendChatMessage({
          msgtype: "image",
          image: {
            mediaid: mediaId, 
          },
        });
      }
      
      this.requestLock = false;

    },

    changeTab(state){
      this.activeTab = state;
      this.activeReplyGroupId = "";
      this.getDataList();
    }
  },
  components:{
    search
  }
}
</script>

<style scoped>

.search_btn_box{
  width: 68px;
  height: 68px;
  background-color: #f2f2f2;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575F76;
  font-size: 40px;
}

.group_list{
  display: flex;
  flex-wrap: wrap;
}

.group_item{
  height: 55px;
  background-color: #ffffff;
  border: 2px solid #dddddd;
  border-radius: 9px;
  padding: 0 25px;
  box-sizing: border-box;
  line-height: 55px;
  font-size: 25px;
  color: #575f76;
  margin-right: 13px;
  margin-bottom: 13px;
}

.group_item_active{
  border: 2px solid #F73B42;
  background-color: #F73B42;
  color: #FFFFFF;
}

.search_margin{
  margin-bottom: 34px;
}

.quick_title{
  font-size: 3px;
  font-weight: 700;
  color: #575f76;
  position: relative;
  padding-left: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.quick_title::before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.24px;
  height: 29.7px;
  background: #f73b42;
}

.quick_content_item{
  font-size: 30px;
  color: #575F76;
  border-bottom: 2.12px solid #f2f2f2;
  height: 96px;
  line-height: 96px;
  position: relative; 
}

.quick_content_item:last-child{
  border-bottom: none;
}

.quick_content_img{
  height: 90px;
  width: 90px;
  position: absolute; 
  right: 0;
  top: 0;
}

.margin_bottom{
  margin-bottom: 150px;
}

.quick_reply_title{
  max-width: 200px;
}

.quick_content_c{
  width: 520px;
}
</style>