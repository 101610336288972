<template>
  <van-dialog :value="value"  :title="title" show-cancel-button 
    @input="input"
    @confirm="$emit('confirm')"
    @cancel="$emit('cancel')"
  >
    <div class="content_text" v-if="contentText">
        {{contentText}}
    </div>
    <template v-else>
        <slot></slot>
    </template>
  </van-dialog>
</template>

<script>
export default {
    props:{
        value:{
            default: false,
            type: Boolean
        },
        title:{
            default: "提示",
            type: String
        },
        contentText:{
            default: "",
            type: String
        }
    },
    methods:{
        input(){
            this.$emit("input", !this.value);
        }
    }
};
</script>

<style  scoped>
.content_text{
    padding: 20px;
    text-align: center;
    word-break: break-all;
    margin-top: 10px;
    font-size: 30px;
}
</style>