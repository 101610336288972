<template>
  <div class="p_search search_margin">
    <input type="text" @keyup.enter="search" :value="value" @input="changeValue" :placeholder="placeholder" class="p_search_input" />
    <div @click="search" class="p_search_btn">
      <van-icon name="search" />
    </div>
  </div>
</template>

<script>
export default {
    props:{
        placeholder:{
            default: "",
            type: String
        },
        value:{
            default: "",
            type: String
        }
    },
    methods:{
        search(e){
            this.$emit("search", e);
        },
        changeValue(e){
            this.$emit("input", e.target.value);
        }
    }
};
</script>

<style scoped>

.p_search{
  width: 100%;
  position: relative;
}

.p_search_input{
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 6px;
  font-size: 26px;
  color: #a6a6a6;
  padding-left: 22px;
  box-sizing: border-box;
  height: 68px;
  width: 100%;
}

.p_search_btn{
  width: 127.29px;
  height: 68px;
  background-color: #f73b42;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>