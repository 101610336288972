import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    corpId: "",         // 应用id ww05178bc3c60fe5d3
    openUserId: "",     // openid
    userId : "",        // userid SunMing
    deviceId : "",      // 设备id
    appContext: "",     
                        /*
                          应用所处环境 通过 企业微信 getContext 获得
                          contact_profile	从联系人详情进入
                          single_chat_tools	从单聊会话的工具栏进入
                          group_chat_tools	从群聊会话的工具栏进入
                          chat_attachment	从会话的聊天附件栏进入
                          normal	除以上场景之外进入，例如工作台，聊天会话等
                        */
    rand: "",            // 企业唯一标识 OO6gkYcY
    agentid: "", // 企业应用agentid

  },
  getters: {},
  mutations: {
    // 修改状态
    changeState(state, {key, value}){
      state[key] = value;
    },
  },
  actions: {
    
  
  }
});
store.commitFun = function(key, value){
  store.commit("changeState", {key,value})
}
export default store;


