<template>
  <div class="bg clear_fix">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}

</script>

<style scoped>

.bg{
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}
</style>
