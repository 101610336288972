import axios from 'axios';
// import qs from 'qs';
import { Toast } from 'vant';

axios.defaults.withCredentials = true;
var instance = axios.create({
    timeout: 30000,
    crossDomain: true,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
});

instance.interceptors.request.use(function (config) {
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  // config.data = qs.stringify(config.data)
  return config;
}, function (error) {

  return Promise.reject(error);

});


instance.interceptors.response.use(function (response) {
  
  if(typeof response.data == 'string'){
    response.data = JSON.parse(response.data);
  }
  return response;

}, function (error) {
 
  Toast.fail('程序异常');
  return Promise.reject(error);
});


export default function (url, data = {}, type = 'post') {

  return new Promise(function (resolve, reject) {
    // 执行异步ajax请求
    let promise = instance.request({
      method: type,
      url: url,
      data: data,
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      reject(error)
    });
  
   
    promise.then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      reject(error)
    });
  })
}