<template>
  <div class="page clear_fix">
    <!-- 客户信息 -->
    <CustomerInfo :customerInfo="customerInfo"></CustomerInfo>

    <!-- 标签 -->
    <Tages 
      :tagList="tagList" 
      :showTagList="showTagList"
      @upDataTages="upDataTages"
      @delTag="delTag"
    />

    <div class="box_s">
      <div class="tab">
        <div @click="changeTab(0)" class="tab_item" :class="{'tab_item_active': activeTab == 0 }">客户信息</div>
        <div @click="changeTab(1)" class="tab_item" :class="{'tab_item_active': activeTab == 1 }">跟进记录</div>
      </div>
      <div class="box_s_c">
        <!-- 客户信息 -->
        <div v-show="activeTab == 0">
            <FollowUpLeft :zdyCustomerInfo="zdyCustomerInfo" @requery="_getCustomerByExternalUserIdandRand" :customer-user-id="customerUserId"></FollowUpLeft>
        </div>
        <!-- 客户信息 -->

        <!-- 跟进记录 -->
        <div v-show="activeTab == 1">
            <FollowUpRight @query-list="queryFollowUpRightList" :follow-list="followList" :customer-user-id="customerUserId"></FollowUpRight>
        </div>
        <!-- 跟进记录 -->

      </div>
    </div>

  </div>
</template>

<script>
  import {
    EVENT_BUS_WORK_WX_AUTH_SUCCESS
  } from "../../constant/eventBusConstant";
  import {
    reqWechatCustomerDetails,
    getLabelList,
    getCustomerByExternalUserIdandRand,
    getCustomerRecordList,
    saveCompanyWechatCustomerTag
  } from '@/request/portrait'
  import {
    mapState
  } from 'vuex'

  // 引入组件
  import CustomerInfo from './components/CustomerInfo.vue'
  import Tages from './components/Tages.vue'
  import FollowUpLeft from './components/FollowUpLeft.vue'
  import FollowUpRight from './components/FollowUpRight.vue'
  import _ from "lodash";
  export default {
    data() {
      return {
        customerInfo: '', //客户信息数据
        activeTab: 0, // 当前激活的tab
        tagList:[],//编辑修改的标签列表
        showTagList:[],//显示的标签列表
        followList:[],//跟进记录
        zdyCustomerInfo: {},//客户信息对象
        customerUserId: "", // 对面的userid
      }
    },
    created() {
      this.$EventBus.$on(EVENT_BUS_WORK_WX_AUTH_SUCCESS, this.authSuccess);

    },
    computed: {
      ...mapState(['corpId', 'userId', 'rand'])
    },
    methods: {
      // 查询跟进记录列表
      queryFollowUpRightList(){
        this._getCustomerRecordList();
      },

      //获取企业微信客户详情
      async _reqWechatCustomerDetails() {
        let reuslt = await reqWechatCustomerDetails({
          companyRand: this.rand,
          userId: this.userId,
          corpId: this.corpId,
          externalUserid: this.customerUserId,
        })

        if (reuslt.status == 1) {
          this.customerInfo = reuslt.data
        }
      },

      //获取标签列表
      async _getLabelList(){
         let result = await getLabelList({
            companyRand:this.rand
         })

         if(result.status == 1){
             this.tagList = result.data
         }
      },

      //查询跟进记录
      async _getCustomerRecordList(){
        
        let result = await getCustomerRecordList({
            companyRand:this.rand,
            corpid:this.corpId,
            userid:this.userId,
            externalUserId: this.customerUserId,
        })

        if(result.status == 1){
          this.followList = result.data
        }
      },

      //自定义企业微信客户信息查询
      async _getCustomerByExternalUserIdandRand(){
        // wmXHBuEwAAt1FZ4j_lKy3n2u4wAVCNcg
        let result = await getCustomerByExternalUserIdandRand({
            externalUserId: this.customerUserId,
            userId:this.userId,
            corpId:this.corpId,
            rand:this.rand
        })

        if(result.status == 1){
          if(result.data){
            this.zdyCustomerInfo = result.data;
          } else {
            this.zdyCustomerInfo = {};
          }
          
          if(result.data && result.data.tagList){
            this.showTagList = result.data.tagList
          }

        }
      },

      //保存企业微信客户信息标签
      async _saveCompanyWechatCustomerTag(taglist){
          let result = await saveCompanyWechatCustomerTag({
              rand:this.rand,
              externalUserId: this.customerUserId,
              userId:this.userId,
              corpId:this.corpId,
              tagList:taglist
          })

          if(result.status == 1){
            this.showTagList = taglist
             this.$toast('操作成功');
          }
      },

      //添加/修改标签(自定义事件)
      upDataTages(obj){
          //先把标签添加到当前数组 然后在把整个数组传给后台 所以 应该成功的时候在赋值，所以需要数组深度克隆
          let deepTagList = _.cloneDeep(this.showTagList)
          deepTagList.push(obj)
          this._saveCompanyWechatCustomerTag(deepTagList)
      },

      //删除标签（自定义事件）
      delTag(index){
        let deepTagList = _.cloneDeep(this.showTagList)
        deepTagList.splice(index,1)
        this._saveCompanyWechatCustomerTag(deepTagList)
      },

      // 授权成功后
      async authSuccess() {
        this.customerUserId = await this.getUserId();
        this._reqWechatCustomerDetails()
        this._getLabelList()
        this._getCustomerRecordList()
        this._getCustomerByExternalUserIdandRand()
      },

      // 获取聊天用户在企业微信里的id
      getUserId() {
        return new Promise((resolve, reject) => {
          window.wx.invoke('getCurExternalContact', {}, (reuslt) => {
            if (reuslt.err_msg == "getCurExternalContact:ok") {
              resolve(reuslt.userId);
            } else {
              reject();
            }
          });
        });
      },

      
      //tab切换
      changeTab(value) {
        this.activeTab = value;
      },





    },
    components: {
 
      CustomerInfo,
      Tages,
      FollowUpLeft,
      FollowUpRight
    }
  }
</script>

<style scoped>
  .box_s {
    width: 700px;
    margin: 25px auto;
    box-sizing: border-box;
    background-color: #ffffff;
  }

  .box_s_c {
    padding: 25px;
    box-sizing: border-box;
  }

  .user_info {
    display: flex;
    align-items: center;
  }

  .user_avt {
    width: 116px;
    height: 116px;
    border-radius: 50%;
  }

  .user_name {
    color: #575F76;
    font-size: 34px;
    margin-bottom: 14px;
    font-weight: bold;
    word-break: break-word;
  }

  .user_info_type {
    font-size: 25px;
    color: #26c445;
  }

  .user_sex {
    width: 34px;
    height: 34px;
  }

  .user_info_value_s {
    color: #3C78FF;
  }

  .label_list {
    display: flex;
    flex-wrap: wrap;
  }


  .tab {
    border-bottom: 2px solid #f2f2f2;
    box-sizing: border-box;
    height: 116px;
    padding: 42px 25px;
    display: flex;
  }

  .tab_item {
    font-size: 30px;
    color: #575f76;
    margin-right: 46px;
  }

  .tab_item_active {
    font-weight: bold;
  }


  .handel_list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }


  .list_container {
    width: 640px;
    box-sizing: border-box;
    height: 70vh;
    overflow: auto;
  }

  .list_container .user_info {
    margin-bottom: 20px;
  }

  .list_container .user_info:last-child {
    margin-bottom: 0;
  }

  .customer_item_info {
    font-size: 30px;
    display: flex;
  }

  .customer_item_fixed {
    flex-shrink: 0;
  }

  .customer_item_outer {
    max-width: 300px;
  }
</style>