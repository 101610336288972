<template>
  <div class="page clear_fix">
    <div class="box">
      <p class="title">
        客户名称 <span class="code_describe">专属的拓客拉新活码</span>
      </p>

      <div
        v-show="currentPosterState == 0"
        ref="poster0"
        class="pull_new_code_container"
      >
        <div class="pull_new_code_info">
          <img v-if="!pullNewCodeInfo.avatar" src="~@/assets/img/avt.png" class="pull_new_code_avt">
          <img v-else :src="pullNewCodeInfo.avatar" class="pull_new_code_avt" />
          <div class="pull_new_code_info_r">
            <p class="pull_new_code_name text_one_line_overflow_hide">{{pullNewCodeInfo.name}}</p>
            <p class="pull_new_code_ins_name text_one_line_overflow_hide">
              {{pullNewCodeInfo.corpName}}
            </p>
          </div>
        </div>
        <img :src="pullNewCodeInfo.qrCode" class="pull_new_code" />
        <p class="pull_new_code_desc">扫一扫二维码图案，加我企业微信</p>
      </div>

      <div
        v-show="currentPosterState == 1"
        ref="poster1"
        class="pull_new_code_container pull_new_code_container1"
      >
        <img v-if="!pullNewCodeInfo.avatar" src="~@/assets/img/avt.png" class="pull_new_code_avt">
        <img v-else :src="pullNewCodeInfo.avatar"  class="pull_new_code_avt" />
        <div class="pull_new_code_info_r">
          <p class="pull_new_code_name text_one_line_overflow_hide">{{pullNewCodeInfo.name}}</p>
          <p class="pull_new_code_ins_name text_one_line_overflow_hide">
            {{pullNewCodeInfo.corpName}}
          </p>
        </div>

        <img :src="pullNewCodeInfo.qrCode" class="pull_new_code" />
        <p class="pull_new_code_desc">扫一扫上面的二维码图案加我企业微信</p>
      </div>

      <div
        v-show="currentPosterState == 2"
        ref="poster2"
        class="pull_new_code_container pull_new_code_container2"
      >
        <div class="avt_box"
          
        >
          <img v-if="!pullNewCodeInfo.avatar" src="~@/assets/img/avt.png" class="pull_new_code_avt">
          <img v-else class="pull_new_code_avt" :src="pullNewCodeInfo.avatar" alt="">
          
        </div>
        <div class="pull_new_code_container2_b">
            <div>
              <p class="ins_name text_one_line_overflow_hide">{{pullNewCodeInfo.corpName}}</p>
              <p class="wx_nick_name text_one_line_overflow_hide">{{pullNewCodeInfo.name}}</p>
            </div>
            <img class="wx_qr_code" :src="pullNewCodeInfo.qrCode" alt="">
          </div>
      </div>

      <div class="poster_switch_btn_list">
        <div
          @click="switchPoster(0)"
          :class="{ poster_switch_btn_active: currentPosterState == 0 }"
          class="poster_switch_btn"
        >
          <div class="poster_switch_btn_t">
            <img
              class="poster_switch_p"
              src="~@/assets/img/qr_code_person.svg"
              alt=""
            />
            <div class="poster_switch_block1"></div>
          </div>
          <div class="poster_switch_block4"></div>
          <img
            class="poster_switch_q"
            src="~@/assets/img/qr_code_example.svg"
            alt=""
          />
          <div class="poster_switch_block2"></div>
        </div>

        <div
          @click="switchPoster(1)"
          :class="{ poster_switch_btn_active: currentPosterState == 1 }"
          class="poster_switch_btn"
        >
          <img
            class="poster_switch_p"
            src="~@/assets/img/qr_code_person.svg"
            alt=""
          />
          <div class="poster_switch_block1"></div>
          <img
            class="poster_switch_q"
            src="~@/assets/img/qr_code_example.svg"
            alt=""
          />
          <div class="poster_switch_block2"></div>
        </div>

        <div
          @click="switchPoster(2)"
          :class="{ poster_switch_btn_active: currentPosterState == 2 }"
          class="poster_switch_btn"
        >
          <div class="poster_switch_btn_t" >
            <img
              class="poster_switch_p"
              src="~@/assets/img/qr_code_person.svg"
              alt=""
            />
            <div class="poster_switch_block1"></div>
          </div>
          <div class="poster_switch_block3"></div>
          <img
            class="poster_switch_q"
            src="~@/assets/img/qr_code_example.svg"
            alt=""
          />
          <div class="poster_switch_block2"></div>
        </div>
      </div>

      <div class="btn_list">
        <button @click="getPoster" class="bottom_btn_l">发送专属二维码</button>
        <button @click="showPullNewData" class="bottom_btn_r" >
          拉新数据
        </button>
      </div>
    </div>

    <van-popup
      v-model="isShowPullNewData"
      :round="true"
      :closeable="true"
      position="bottom"
    >
      <div class="clear_fix">
        <p class="area_title">拉新数据</p>
        <div class="user_item">
          <img v-if="!pullNewData.avater" src="~@/assets/img/avt.png" class="user_avt">
          <img v-else class="user_avt" :src="pullNewData.avater" alt="" />
          <div class="user_item_r">
            <div class="user_item_r_t">
              <p class="user_item_name text_one_line_overflow_hide">{{pullNewData.name}}</p>
              <img
                v-if="pullNewData.gender == 1"
                class="user_item_sex"
                src="~@/assets/img/male.svg"
              />
              <img
                v-if="pullNewData.gender == 2"
                class="user_item_sex"
                src="~@/assets/img/female.svg"
              />
            </div>
            <p class="user_item_data">客户拉新{{pullNewData.customerPullNewList.length}}人 今日拉新{{pullNewData.todayPullNewList.length}}人</p>
          </div>
        </div>
        <div class="quick_tab tab_box">
          <div class="quick_tab_box" style="margin-bottom: 0;">
            <div @click="changeTab(1)" class="quick_tab_item" :class="{'quick_tab_item_active': type == 1}">
              客户拉新
            </div>
            <div @click="changeTab(2)" class="quick_tab_item" :class="{'quick_tab_item_active': type == 2}">
              今日拉新
            </div>
          </div>
        </div>
        <div class="user_list">
          <div v-for="(item,index) in pullNewData.dataList" :key="index" class="user_item">
            <img v-if="!item.avatar" src="~@/assets/img/avt.png" class="user_avt">
            <img v-else class="user_avt" :src="item.avatar" alt="" />
            <div class="user_item_r">
              <div class="user_item_r_t">
                <p class="user_item_name text_one_line_overflow_hide">{{item.name}}</p>
                <img
                  v-if="item.gender == 1"
                  class="user_item_sex"
                  src="~@/assets/img/male.svg"
                  alt=""
                />
                <img
                  v-if="item.gender == 2"
                  class="user_item_sex"
                  src="~@/assets/img/female.svg"
                  alt=""
                />
                <p class="user_item_flag">
                  <template v-if="item.type == 1">微信</template>
                  <template v-if="item.type == 2">{{item.corpName}}</template>
                </p>
              </div>
              <p class="user_item_time">{{item.createTime}}</p>
            </div>
          </div>
          <van-empty v-if="pullNewData.dataList.length == 0" description="暂无数据" />
        </div>
      </div>
    </van-popup>

    <package-loading :is-show="lock"></package-loading>

  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { EVENT_BUS_WORK_WX_AUTH_SUCCESS } from "../../constant/eventBusConstant";
import { dataURLtoFile } from "../../utils/index";
import { pullNewData, getPullNewCode, uploadQywxMedia } from "../../request/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      currentPosterState: 0,
      isShowPullNewData: false,
      customerUserId: "", // 客户的userid
      pullNewData: {
        customerPullNewList:[],
        todayPullNewList:[],
        dataList:[],
        avater: "",
        gender: "",
        name: "",
      },
      pullNewCodeInfo:{}, // 拉新数据
      type: 1, // 1-客户拉新 2-今日拉新
      lock: false,
    };
  },
  created(){
    this.$EventBus.$on(EVENT_BUS_WORK_WX_AUTH_SUCCESS, this.authSuccess);
  },
  computed: {
      ...mapState(['corpId','userId','rand']),
  },
  methods: {
    changeTab(type){
      if(this.type == type){
        return 
      }
      this.type = type;
      this.getPullNewData();
    },

    async authSuccess(){
      // 获取对面的userid
      this.customerUserId = await this.getUserId();
      // 获取拉新数据
      this.getPullNewData();
      this.getPullNewCode();
    },

    async getPullNewData(){
      let result = await pullNewData({
        companyRand: this.rand,
        corpid: this.corpId, 
        externalUserid: this.customerUserId,
        servicerUserid: this.userId,
      });

      if(!result.data){
        result.data = {};
      }

      if(!result.data.todayPullNewList){
        result.data.todayPullNewList = [];
      }

      if(!result.data.customerPullNewList){
        result.data.customerPullNewList = [];
      }

      if(this.type == 1){
        this.pullNewData.dataList = result.data.customerPullNewList;
      }

      if(this.type == 2){
        this.pullNewData.dataList = result.data.todayPullNewList;
      }

      this.pullNewData.customerPullNewList = JSON.parse(JSON.stringify(result.data.customerPullNewList));

      this.pullNewData.todayPullNewList =  JSON.parse(JSON.stringify(result.data.todayPullNewList));

      this.pullNewData.avater = result.data.avater;
      this.pullNewData.gender = result.data.gender;
      this.pullNewData.name = result.data.name;

      if(result.status != 1){
        this.$toast(result.msg);
      }
    },

    async getPullNewCode(){
      let result = await getPullNewCode({
          companyRand: this.rand,
          corpid: this.corpId, 
          externalUserid: this.customerUserId,
          servicerUserid: this.userId,
      });

      if(result.data.avatar){
        result.data.avatar = 'data:image/png;base64,' + result.data.avatar;
      }

 
      this.pullNewCodeInfo = result.data;

      if(result.status != 1){
        this.$toast(result.msg);
      }
    },

    // 获取聊天用户在企业微信里的id
    getUserId(){
      return new Promise((resolve, reject) => {
        window.wx.invoke('getCurExternalContact', {}, (reuslt) => {
          if(reuslt.err_msg == "getCurExternalContact:ok"){
            resolve(reuslt.userId);
          } else {
            reject();
          }
        });
      });
    },

    async getPoster() {
      if(this.lock){
        return
      }
      this.lock = true;

      try{
        let el = this.$refs["poster" + this.currentPosterState];
        let result = await html2canvas(el, {
          allowTaint: true,
          useCORS: true,
        });
        
        let file = dataURLtoFile(result.toDataURL(), new Date().valueOf());

        let fromData = new FormData();
        fromData.append("corpid", this.corpId);
        fromData.append("file", file);
        fromData.append("companyRand", this.rand);

        let uploadQywxMediaResult = await uploadQywxMedia(fromData)
      
        if(uploadQywxMediaResult.status == 1){
          window.wx.invoke("sendChatMessage",{
            msgtype: "image", //消息类型，必填
            image: {
              mediaid: uploadQywxMediaResult.data.mediaId, //图片的素材id
            }
          });

        } else {
          this.$toast(uploadQywxMediaResult.msg);
        }
      } finally {
        this.lock = false;
      }

    },

    // 查看拉新数据
    showPullNewData() {
      this.isShowPullNewData = true;
    },

    // 切换名片模板
    switchPoster(state) {
      if (this.currentPosterState == state) {
        return;
      }
      this.currentPosterState = state;
    },
  },
};
</script>

<style scoped>
.code_describe {
  color: #a6a6a6;
}

.pull_new_code_container {
  border: 2.12px solid #ebebeb;
  border-radius: 16.97px;
  box-sizing: border-box;
  padding: 40px 34px;
  margin: 30px auto 0;
}

.pull_new_code_avt {
  width: 103.95px;
  height: 103.95px;
  border-radius: 50%;
  margin-right: 34px;
  flex-shrink: 0;
}

.pull_new_code_info {
  display: flex;
  align-items: center;
}

.pull_new_code_info_r {
  flex-grow: 1;
}

.pull_new_code_name {
  font-size: 33.94px;
  font-weight: 700;
  text-align: left;
  color: #575f76;
  max-width: 210px;
  margin-bottom: 5px;
}

.pull_new_code_ins_name {
  font-size: 29.7px;
  font-weight: 400;
  text-align: left;
  color: #a6a6a6;
  max-width: 210px;
}

.pull_new_code {
  width: 430.67px;
  height: 432.79px;
  display: block;
  margin: 32px auto 26px auto;
}

.pull_new_code_desc {
  font-size: 25.46px;
  text-align: center;
  color: #575f76;
}

.pull_new_code_container1 .pull_new_code_avt {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.pull_new_code_container1 .pull_new_code_name {
  text-align: center;
  max-width: none;
}

.pull_new_code_container1 .pull_new_code_ins_name {
  text-align: center;
  max-width: none;
}

.pull_new_code_container1 .pull_new_code {
  width: 350px;
  height: 350px;
  margin-bottom: 80px;
}

.pull_new_code_container1 .pull_new_code_desc{
  font-size: 38px;
  font-weight: bold;
  color: #8b8b8b;
  line-height: 55px;
  width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.pull_new_code_container2{
  padding-left: 0;
  padding-right: 0;
}

.pull_new_code_container2 .avt_box{
  width: 646px;
  height: 646px;
  position: relative;
}

.pull_new_code_container2 .pull_new_code_avt {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.pull_new_code_container2 .avt_img{
  width: 100%;
  height: 100%;
  display: block;
}

.pull_new_code_container2 .wx_nick_name{
  font-size: 35px;
  font-weight: 400;
  color: #686868;
  font-weight: bold;
  max-width: 600px;
  margin-top: 20px;
}

.pull_new_code_container2 .pull_new_code_container2_b{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 48px;
  padding-bottom: 0;
}

.pull_new_code_container2 .ins_name{
  font-weight: 400;
  text-align: left;
  color: #a6a6a6;
  max-width: 400px;
  font-weight: bold;
  font-size: 35px;
}

.pull_new_code_container2 .wx_qr_code{
  width: 200px;
  height: 200px;
}

.poster_switch_btn_list {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.poster_switch_btn {
  width: 190.94px;
  height: 328.84px;
  border: 2.12px solid #ebebeb;
  border-radius: 16.97px;
  box-sizing: border-box;
  padding: 28px 0px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.poster_switch_p {
  width: 53px;
}

.poster_switch_q {
  width: 110px;
  margin-bottom: 35px;
}

.poster_switch_block1 {
  width: 67.89px;
  height: 19.09px;
  background: #c0c0c1;
  margin-top: 19px;
  margin-bottom: 24px;
}

.poster_switch_block2 {
  width: 110.32px;
  height: 12.73px;
  background: #e9e9e9;
}

.poster_switch_btn_t {
  display: flex;
  justify-content: space-between;
  width: 140px;
}

.poster_switch_block3 {
  width: 110.32px;
  height: 12.73px;
  background: #c0c0c1;
  margin-top: 15px;
  margin-bottom: 24px;
}

.poster_switch_block4 {
  width: 110.32px;
  height: 12.73px;
  margin-top: 15px;
  margin-bottom: 24px;
}

.poster_switch_btn_active {
  box-shadow: 0px 2px 6px 0px rgba(47, 47, 47);
}

.send_qr_btn {
  width: 379.75px;
  height: 67.89px;
  background: #f73b42;
  border-radius: 8.49px;
  font-size: 29.7px;
  text-align: center;
  color: #ffffff;
  line-height: 67.89px;
}

.btn_list {
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
}

.perview_pull_data_btn {
  width: 229.13px;
  height: 67.89px;
  background: #ffebec;
  border-radius: 8.49px;
  font-size: 29.7px;
  text-align: center;
  color: #f73b42;
  line-height: 67.89px;
}

.user_avt {
  width: 101.83px;
  height: 101.83px;
  border-radius: 50%;
  margin-right: 34px;
}

.user_item {
  display: flex;
  padding-left: 24px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.user_item:last-child {
  margin-bottom: 0px;
}

.user_list {
  width: 90vw;
  height: 50vh;
  background: #f6f7f8;
  border-radius: 16.97px;
  margin: 30px auto;
  padding: 30px 0;
}

.user_item_name {
  font-size: 25.46px;
  color: #575f76;
  max-width: 300px;
}

.user_item_sex {
  width: 29.7px;
  margin: 0 18px;
}

.user_item_flag {
  font-size: 25.46px;
  color: #14c672;
}

.user_item_r_t {
  display: flex;
}

.user_item_time {
  font-size: 25.46px;
  color: #a6a6a6;
  margin-top: 14px;
}

.user_item_r {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_item_data {
  font-size: 25.46px;
  color: #575f76;
  margin-top: 21px;
}

.tab_box{
  margin: 0px auto;
  width: 90vw;
}
</style>