<template>
  <div class="page clear_fix">
    <div class="box">
        <div class="from_item">
          <div class="from_title">客户</div>
          <div class="from_content">
            <input type="text" placeholder="请输入客户名称" class="from_input" v-model="customeInfo.customerName"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">联系人</div>
          <div class="from_content">
            <input type="text" placeholder="请输入联系人名称" class="from_input" v-model="customeInfo.contacts"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">年龄</div>
          <div class="from_content">
            <input type="text" placeholder="请输入年龄" class="from_input" v-model="customeInfo.age"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">电话</div>
          <div class="from_content">
            <input type="text" placeholder="请输入电话" class="from_input" v-model="customeInfo.phone"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">QQ</div>
          <div class="from_content">
            <input type="text" placeholder="请输入QQ" class="from_input" v-model="customeInfo.qq"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">微信</div>
          <div class="from_content">
            <input type="text" placeholder="请输入微信" class="from_input" v-model="customeInfo.wechat"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">微博</div>
          <div class="from_content">
            <input type="text" placeholder="请输入微博" class="from_input" v-model="customeInfo.vBlog"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">地址</div>
          <div class="from_content">
            <input type="text" placeholder="请输入地址" class="from_input" v-model="customeInfo.address"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">邮箱</div>
          <div class="from_content">
            <input type="text" placeholder="请输入邮箱" class="from_input" v-model="customeInfo.email"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">星级</div>
          <div class="from_content">
            <van-rate v-model="customeInfo.starId"/>
          </div>
        </div>
        <div class="from_item">
          <div class="from_title">备注</div>
          <div class="from_content">
            <textarea rows="5" class="textareaOne" placeholder="请输入备注" v-model="customeInfo.remarks"></textarea>
          </div>
        </div>
        <div class="from_btn_list">
          <button @click="back" class="while_btn">取消</button>
          <button class="imp_btn" @click="handleSubmit">保存</button>
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {
  EVENT_BUS_WORK_WX_AUTH_SUCCESS
} from "../../constant/eventBusConstant";
import {
    getCustomerByExternalUserIdandRand,
    saveCompanyWechatCustomer
  } from '@/request/portrait'
export default {
  data(){
    return {
      customerUserId: "",
      custId:'',
      customeInfo:{
        contacts:'',
        customerName:'',
        age:'',
        gender:'',
        phone:'',
        qq:'',
        wechat:'',
        vBlog:'',
        address:'',
        email:'',
        starId:1,
        remarks:''
      }
    }
  },
  computed:{
    ...mapState(['corpId', 'userId', 'rand'])
  },
  created(){
    this.$EventBus.$on(EVENT_BUS_WORK_WX_AUTH_SUCCESS, this.authSuccess);
  },
  methods:{
    async authSuccess(){
      this.customerUserId = await this.getUserId();
      this.custId = Number(this.$route.query.id)
      this._getCustomerByExternalUserIdandRand()
    },
    // 获取聊天用户在企业微信里的id
    getUserId() {
      return new Promise((resolve, reject) => {
        window.wx.invoke('getCurExternalContact', {}, (reuslt) => {
          if (reuslt.err_msg == "getCurExternalContact:ok") {
            resolve(reuslt.userId);
          } else {
            reject();
          }
        });
      });
    },

    back(){
      this.$router.push('/customerPortrait')
    },
    //自定义企业微信客户信息查询
    async _getCustomerByExternalUserIdandRand(){
      let result = await getCustomerByExternalUserIdandRand({
          externalUserId: this.customerUserId,
          userId:this.userId,
          corpId:this.corpId,
          rand:this.rand
      })

      if(result.status == 1){
        this.customeInfo.contacts = result.data.contacts
        this.customeInfo.customerName = result.data.customerName
        this.customeInfo.age = result.data.age
        this.customeInfo.gender = result.data.gender
        this.customeInfo.phone = result.data.phone
        this.customeInfo.qq = result.data.qq
        this.customeInfo.wechat = result.data.wechat
        this.customeInfo.vBlog = result.data.vBlog
        this.customeInfo.address = result.data.address
        this.customeInfo.email = result.data.email
        this.customeInfo.starId = result.data.starId
        this.customeInfo.remarks = result.data.remarks

      }
    },
    //保存内容
    async handleSubmit(){
      if ( this.customeInfo.customerName &&  this.customeInfo.customerName.length > 30) {
        this.$toast('名称最多输入30个字符')

        return
      }
      if (this.customeInfo.contacts && this.customeInfo.contacts.length > 30) {
        this.$toast('联系人最多输入30个字符')
        return
      }


      if (this.customeInfo.phone && this.customeInfo.phone.length > 30) {
        this.$toast('电话号码最多输入30个字符')
        return
      }

      if(this.customeInfo.phone){
        if(!/^[0-9]*$/.test(this.customeInfo.phone)){
          this.$toast('电话号码格式不正确')
          return
        }
      }

      if(this.customeInfo.age){
        if(!/^[0-9]*$/.test(this.customeInfo.age) || this.customeInfo.age.length > 3 ){
          this.$toast('请输入正确的年龄')
          return
        }
      }

      if (this.customeInfo.qq && this.customeInfo.qq.length > 30) {
        this.$toast('QQ号码最多输入30个字符')
        return
      }

      if (this.customeInfo.wechat && this.customeInfo.wechat.length > 30) {
        this.$toast('微信号码最多输入30个字符')
        return
      }

      if (this.customeInfo.vBlog && this.customeInfo.vBlog.length > 30) {
        this.$toast('微博号码最多输入30个字符')
        return
      }



      if (this.customeInfo.address && this.customeInfo.address.length > 30) {
        this.$toast('地址最多输入30个字符')
        return
      }

      if (this.customeInfo.email && this.customeInfo.email.length > 30) {
        this.$toast('邮箱最多输入30个字符')
        return
      }

      if (this.customeInfo.remarks && this.customeInfo.remarks.length > 500) {
        this.$toast('备注最多输入500个字符')
        return
      }
      let result = await saveCompanyWechatCustomer({
        rand:this.rand,
        externalUserId:this.customerUserId,
        userId:this.userId,
        corpId:this.corpId,
        customerId:this.custId,
        contacts:this.customeInfo.contacts,
        customerName:this.customeInfo.customerName,
        age:this.customeInfo.age,
        gender:this.customeInfo.gender,
        phone:this.customeInfo.phone,
        qq:this.customeInfo.qq,
        wechat:this.customeInfo.wechat,
        vBlog:this.customeInfo.vBlog,
        address:this.customeInfo.address,
        email:this.customeInfo.email,
        starId:this.customeInfo.starId,
        remarks:this.customeInfo.remarks
      })
      if(result.status ==1){
        this.$toast(result.msg)
        setTimeout(() => {
          this.back();
        }, 1500);
      }else{
        this.$toast(result.msg)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.textareaOne{
  border: 1px solid #dddddd;
  outline: none;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 26px;
  padding-left: 10px;
  line-height: 30px;
  padding-top: 10px;
  resize: none;
}
textarea::-webkit-input-placeholder {
  color: #a6a6a6;
}
textarea:-moz-placeholder {
  color: #a6a6a6;
}
textarea::-moz-placeholder {
  color: #a6a6a6;
}
textarea::-ms-input-placeholder {
  color: #a6a6a6;
}
</style>