<template>
    <van-overlay class-name="overlay" :show="isShow" >
        <div class="wapper">
            <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
    </van-overlay>
</template>

<script>
export default {
    props:{
        isShow:{
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
.wapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.overlay{
    background-color: rgba(255,255,255,0.7);
}
</style>