<template>
    <!--跟进右边  -->
    <div>
        <button @click="addRecord()" class="add_record_btn">添加跟进记录</button>
        <van-steps direction="vertical" :active="followList.length" active-color="#F73B42">
            <van-step v-for="(item,index) in followList" :key="item.id">
                <template slot="finish-icon">
                    <div class="record_right_icon">{{index + 1}}</div>
                </template>
                <p class="record_date">{{item.addTime}}
                    <img v-if="item.type == 2" @click="addRecord(item)" src="~@/assets/img/chatAddReply.svg" class="edit_btn" >
                </p>
                <div class="record_content">{{item.content}}</div>
                <div class="record_img_list">
                    <img v-for="(src) in item.imgList" :src="src" :key="src" class="record_img">
                </div>
            </van-step>
        </van-steps>

        <!-- 添加跟进记录 -->
        <van-popup v-model="isShowAddRecordArea" :round="true" :closeable="true" position="bottom">
            <p class="area_title">添加跟进记录</p>
            <div class="from_container">
                <div class="from_item">
                    <div class="from_title">跟进记录</div>
                    <div class="from_content">
                        <textarea v-model="fromData.content" maxlength="300" class="from_textarea" placeholder="请输入跟进记录"></textarea>
                    </div>
                </div>
                <div class="from_btn_list" style="position: relative;">
                    <p @click="addImg" class="from_tip">添加跟进图片（{{fromData.imgList.length}}）</p>
                    <button @click="isShowAddRecordArea = false" class="while_btn">取消</button>
                    <button @click="saveCustomerRecord" class="imp_btn">保存</button>
                </div>
            </div>
        </van-popup>
        <!-- 添加跟进记录 -->

        <!-- 添加跟进记录上传图片 -->
        <van-popup v-model="isShowAddRecordImageArea" :round="true" :closeable="true" position="bottom">
            <p class="area_title">添加跟进记录</p>
            <div class="from_container">
                <div class="from_item">
                    <div class="from_title">上传图片</div>
                    <div class="from_content up_img_style">
                        <div v-for="(link, index) in imgListBack" :key="link" class="up_img_perview">
                            <i @click="delBtn(index)" class="del_btn van-icon van-icon-cross van-uploader__preview-delete-icon"></i>
                            <img :src="link"  >
                        </div>
                        <div v-show="imgListBack.length < 9" @click="$refs.fileInput.click()" class="up_img_btn">
                        </div>
                    </div>
                </div>

                <div class="from_item">
                    <p @click="isShowAddRecordImageArea = true" class="from_tip" style="position: relative;">
                        *单次仅支持上传9张2M的jpg、png、jpeg格式的图片</p>
                </div>

                <div class="from_btn_list">
                    <button @click="isShowAddRecordImageArea = false" class="while_btn">取消</button>
                    <button @click="saveImg" class="imp_btn">保存</button>
                </div>
            </div>
        </van-popup>
        <!-- 添加跟进记录上传图片 -->

        <input type="file" ref="fileInput" @change="addSingleImg"  accept="image/*" style="display: none;"> 

        <van-loading v-if="lock" size="24px" vertical>加载中...</van-loading>

    </div>
</template>

<script>
    import { saveCustomerRecord } from "@/request/portrait";
    import { imageUpload } from "@/request/api";

    import { mapState } from "vuex";

    export default {
        props:{
            followList:{
                type: Array,
                default: function(){
                    return [];
                },
            },
            customerUserId:{
                type: String,
                default: ""
            }
        },
        computed: {
            ...mapState(['corpId', 'userId', 'rand'])
        },
        data() {
            return {
                isShowAddRecordArea: false, // 是否展示添加根进记录的弹窗
                isShowAddRecordImageArea: false, // 是否展示跟进记录上传图片的弹窗
                fromData:{
                    id: "",
                    content: "",
                    imgList: [],
                },
                imgListBack:[],
                lock: false,
            }
        },
        methods:{
            delBtn(index){
                this.imgListBack.splice(index, 1);
            },

            // 上传单个图片
            async addSingleImg(){
                if(this.lock){
                    return 
                }
                this.lock = true;

                let file = this.$refs.fileInput.files[0];

                let filetypes = ["JPG", "PNG", "JPEG"];
                let strArr = file.name.split(".");
                let lastName = strArr[strArr.length - 1].toUpperCase();
                if(filetypes.indexOf(lastName) == -1){
                    this.$toast("请选择正确的文件类型");
                    this.lock = false;
                    this.$refs.fileInput.value = "";
                    return 
                }

                if (file.size >= 2 * 1000 * 1000) {
                    this.$toast("图片大小不能超过2M，请重新选择");
                    this.lock = false;
                    this.$refs.fileInput.value = "";
                    return 
                }
                let fromData = new FormData();
                fromData.append("file", file);
                let result = await imageUpload(fromData);
                if(result.status == 1){
                    this.imgListBack.push(result.data.url);
                } else {
                    this.$toast(result.msg);
                }
                this.$refs.fileInput.value = "";

                this.lock = false;
            },

            // 选择图片确定
            saveImg(){
                this.fromData.imgList = JSON.parse(JSON.stringify(this.imgListBack));
                this.isShowAddRecordImageArea = false;
            },

            // 打开上传 图片弹窗
            addImg(){
                this.imgListBack = JSON.parse(JSON.stringify(this.fromData.imgList));
                this.isShowAddRecordImageArea = true;
            },

            // 编辑保存跟进记录
            async saveCustomerRecord(){
                if(this.lock){
                    return 
                }
                this.lock = true;

                let result = await saveCustomerRecord({
                    companyRand: this.rand,
                    corpid: this.corpId,
                    userid: this.customerUserId,
                    kfUserid: this.userId,
                    ...this.fromData
                });

                if(result.status == 1){
                    this.$emit("query-list");
                    this.isShowAddRecordArea = false;
                }

                this.$toast(result.msg);

                this.lock = false;
                
            },

            // 添加跟进记录
            addRecord(data){

                if(data) {
                    this.fromData.id = data.id;
                    this.fromData.content = data.content;
                    this.fromData.imgList = data.imgList;
                } else {
                    this.fromData.id = "";
                    this.fromData.content = "";
                    this.fromData.imgList = [];
                }
                
                this.isShowAddRecordArea = true;

            }
        }
    }
</script>

<style lang="scss" scoped>
    .add_record_btn {
        width: 100%;
        height: 69px;
        background: #f73b42;
        border-radius: 9px;
        font-size: 30px;
        text-align: center;
        color: #ffffff;
        line-height: 69px;
        margin-bottom: 42px;
    }

    .record_right_icon {
        width: 34px;
        height: 34px;
        background-color: #f73b42;
        border-radius: 50%;
        line-height: 36px;
        font-size: 26px;
        text-align: center;
        color: #ffffff;
    }

    .record_date {
        font-size: 26px;
        font-weight: 400;
        color: #a1a6b5;
        margin-bottom: 14px;
    }

    .record_content {
        font-size: 30px;
        font-weight: 400;
        color: #575f76;
        margin-bottom: 16px;
    }

    .record_img_list {
        display: flex;
        flex-wrap: wrap;
    }

    .record_img {
        width: 187px;
        height: 187px;
        margin-right: 16px;
    }

    .from_tip {
        font-size: 25.46px;
        color: #a6a6a6;
        position: absolute;
        position: relative;
        left: 0;
    }

    .up_img_perview{
       position: relative;
    }

    .up_img_perview > img {
        width: 100%;
        height: 100%; 
        object-fit: cover;
    }

    .del_btn{
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 100%;
        color: #ffffff;
        font-size: 20px;
        text-align: center;
        line-height: 30px;
        padding-left: 10px;
        padding-bottom: 10px;
    }

   
    .edit_btn{
        width: 25px;
    }
</style>