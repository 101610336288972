// 获取登录标识
export function getLoginFlag(){
  return sessionStorage.getItem("openuserid");
}

// 设置登录标识
export function setLoginFlag(openuserid){
  sessionStorage.setItem("openuserid", openuserid);
}

// 清除登录标识
export function removeLoginFlag(){
  sessionStorage.removeItem("openuserid");
}

// 设置页面title
export function setDocumentTitle(title){
	if(title){
		document.title = title;
	}
}

// 解析url上的所有参数
export function parseUrlPara(){
	let search = location.search.replace("?","");
	let searchArr = search.split("&");
	let result = {};
	for(let item of searchArr){
		let kvArr = item.split("=");
		let key = kvArr[0];
		let value = kvArr[1];
		if(key){
			result[key] = value;
		}
	}
	return result;
}

//将base64转换为文件对象
export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',');
	var mime = arr[0].match(/:(.*?);/)[1];
	var bstr = atob(arr[1]);
	var n = bstr.length;
	var u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	//转换成file对象
	return new File([u8arr], filename + "." + mime.split('/')[1], {type: mime});
}

// 将图片链接转化为base64
export function getBase64Image(src) {
	let img = new Image();
	img.src = src;
	img.setAttribute('crossOrigin', 'Anonymous');
	
	return new Promise((resolve) => {
		img.onload = () => {
			let canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			let ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, img.width, img.height);
			let dataURL = canvas.toDataURL();
			
			resolve(dataURL);
		}
	});
	
}