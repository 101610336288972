<template>
    <!--跟进左边  -->
    <div>
        <div class="handel_list">
            <button @click="handleOpenCust" class="bottom_btn_l">关联已有用户</button>
            <button @click="goEdit" class="bottom_btn_r">编辑</button>
        </div>
        <!-- 展示用户信息 -->
        <div>
          <div class="user_info_item">
              <p class="user_info_label">客户</p><p class="user_info_value"> {{zdyCustomerInfo.customerName}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">联系人</p><p class="user_info_value">{{zdyCustomerInfo.contacts}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">年龄</p><p class="user_info_value">{{zdyCustomerInfo.age}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">电话</p><p class="user_info_value">{{zdyCustomerInfo.phone}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">QQ</p><p class="user_info_value">{{zdyCustomerInfo.qq}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">微信</p><p class="user_info_value">{{zdyCustomerInfo.wechat}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">微博</p><p class="user_info_value">{{zdyCustomerInfo.vBlog}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">地址</p><p class="user_info_value">{{zdyCustomerInfo.address}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">邮箱</p><p class="user_info_value">{{zdyCustomerInfo.email}}</p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">星级</p><p class="user_info_value">
                  <van-rate v-model="zdyCustomerInfo.starId" readonly  />
              </p>
          </div>
          <div class="user_info_item">
              <p class="user_info_label">备注</p><p class="user_info_value">{{zdyCustomerInfo.remarks}}</p>
          </div>
        </div>

        <!-- 关联弹窗 -->
        <van-popup v-model="isShowRelationCustomerArea" :round="true" :closeable="true" position="bottom" @click-close-icon="cancelChoose">
            <p class="area_title">关联已有客户</p>
            <div class="from_container">
                <div class="from_item">
                    <p class="relation_customer_p">已关联客户：{{zdyId == ''?'':zdyCustomerInfo.relationCustomerName}}
                        <van-icon name="clear" @click="zdyId = ''" v-if="zdyId"/>
                    </p>
                </div>
                <div class="from_item filter_customer">
                    <div class="from_select filter_customer_select" @click="showGroup = true" style="padding-right:30px;">
                        {{chooseExistGroup.groupName}}
                    </div>
                    <search class="search_margin filter_customer_search" placeholder="请输入客户名称" v-model="searchName" @search="_selectCompanyWechatGLCustomerList"></search>
                </div>

                <div class="from_item">
                  <van-radio-group v-model="radio">
                    <div class="customer_item" v-for="(item,index) in existCustList" :key="index">
                        <van-radio :name="item.customerId" class="customer_item_radio"></van-radio>
                        <div>
                            <p class="customer_item_t1">{{item.customerName}}</p>
                            <p class="customer_item_t2">{{item.contacts}}
                                <!-- <img src="~@/assets/img/male.svg" alt=""> -->
                                <img class="customer_sex" src="@/assets/img/female.svg" alt="" v-if="item.gender == 0"/>
                                <img class="customer_sex" src="@/assets/img/female.svg" alt="" v-if="item.gender == 1"/>
                            </p>
                            <p class="customer_item_t3">{{item.phone}}</p>
                        </div>
                    </div>
                  </van-radio-group>

                </div>

                <div class="from_btn_list">
                    <button class="while_btn" @click="cancelChoose">取消</button>
                    <button class="imp_btn" @click="_relationCompanyWechatCustomer">保存</button>
                </div>
            </div>

        </van-popup>

        <van-popup v-model="showGroup" position="bottom">
          <van-picker title="选择分组" show-toolbar :columns="existGroupList" value-key="groupName" default-index="0"
          @confirm="onConfirm" @cancel="onCancel" @change="onChange"/>
        </van-popup>
        

        <!-- 取消关联 -->
        <handel-tip v-model="isShowDelLabel" @confirm="isShowDelLabel = false" @cancel="isShowDelLabel = false"
            content-text="确定要取消关联吗？">
        </handel-tip>
        <!-- 取消关联 -->
    </div>
</template>

<script>
    import search from "@/components/commonSearch.vue";
    import {mapState} from 'vuex'
    import {cancelRelationCompanyWechatCustomer,
            selectCompanyWechatGLCustomerList,
            getCustomerGroupListforCompanyWechat,
            relationCompanyWechatCustomer} from '@/request/portrait.js'
    export default {
        props:['zdyCustomerInfo','customerUserId'],
        components: {
          search,
        },
        computed: {
          ...mapState(['corpId', 'userId', 'rand'])
        },
        data() {
            return {
                isShowRelationCustomerArea: false, // 是展示关联客户的弹窗  
                existCustList:[],//已有客户列表
                existGroupList: [], //已有客户分组列表
                chooseExistGroup: {}, //选择的客户分组
                showGroup:false, //展示已有客户分组弹窗
                searchName:'', //搜索名称
                radio:'',
                zdyId:this.zdyCustomerInfo.customerId,
                isShowDelLabel:false,
            }
        },
        created(){
          this.zdyId = this.zdyCustomerInfo.customerId;
        },
        mounted(){
          this._getCustomerGroupListforCompanyWechat()
        },
        methods: {
            goEdit() {
                this.$router.push({path:"/customerPortrait/eidt",query:{id:this.zdyCustomerInfo.customerId}});
            },
            //打开已有客户弹窗
            handleOpenCust(){
              this.isShowRelationCustomerArea = true
              if(this.zdyCustomerInfo.customerId){
                this.radio = this.zdyCustomerInfo.customerId
              }
              this._getCustomerGroupListforCompanyWechat()
            },

            //确认关联
            async _relationCompanyWechatCustomer(){
              let result = await relationCompanyWechatCustomer({
                rand:this.rand, 
                externalUserId: this.customerUserId,
                userId:this.userId,
                corpId:this.corpId,
                customerId:this.radio
              })
              if(result.status == 1){
                this.isShowRelationCustomerArea = false
                this.$emit('requery')
                this.$toast(result.msg)
                this.searchName = ''
                this._selectCompanyWechatGLCustomerList()
              }else{
                this.$toast(result.msg)
              }
            },


            //取消关联
            // async _cancelRelationCompanyWechatCustomer(){
            //   let result = await cancelRelationCompanyWechatCustomer({
            //     rand:this.rand, 
            //     externalUserId:'wmXHBuEwAAqrBvzwRHmodqAUR-T_eV3A',
            //     userId:this.userId,
            //     corpId:this.corpId
            //   })
            //   if(result.status == 1){
            //     this.$emit('requery')
            //     this.isShowRelationCustomerArea =false
            //     this.$toast(result.msg);
            //     this._selectCompanyWechatGLCustomerList()
            //   }else{
            //     this.$toast(result.msg);
            //   }
            // },

            //查询已有客户列表
            async _selectCompanyWechatGLCustomerList(){
              console.log(this.chooseExistGroup)
              let result = await selectCompanyWechatGLCustomerList({
                rand:this.rand,
                keyWord:this.searchName,
                groupId:this.chooseExistGroup.groupId?this.chooseExistGroup.groupId:'',
              })
              if(result.status == 1){
                this.existCustList = result.data.tableData
              }else{
                this.$toast(result.msg);
              }
            },

            //查询已有客户分组列表
            async _getCustomerGroupListforCompanyWechat(){
              let result = await getCustomerGroupListforCompanyWechat({
                rand:this.rand
              })
              if(result.status == 1){
                this.existGroupList = result.data
                if(this.existGroupList && this.existGroupList[0]){
                  this.chooseExistGroup = this.existGroupList[0];
                } else {
                  this.chooseExistGroup = {};
                }
                
                this._selectCompanyWechatGLCustomerList()
              }else{
                this.$toast(result.msg)
              }
            },
            onConfirm(value, index) {
              this.chooseExistGroup = value
              this.showGroup = false
              this.radio = ''
              this._selectCompanyWechatGLCustomerList()
            },
            onChange(picker, value, index) {
              // Toast(`当前值：${value}, 当前索引：${index}`);
            },
            onCancel() {
              this.showGroup = false
            },

            //取消关联
            cancelChoose(){
              this.searchName = ''
              this.radio = ''
              this.isShowRelationCustomerArea = false
            }
            
        }
    }
</script>

<style lang="scss" scoped>
    .handel_list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .user_info_item {
        display: flex;
        margin-bottom: 30px;
    }

    .user_info_label {
        width: 120px;
        color: #A6A6A6;
        font-size: 30px;
        margin-right: 42px;
    }

    .user_info_value {
        color: #575F76;
        font-size: 30px;
        flex-grow: 1;
        word-break: break-all;
        line-height: 46px;
    }

    .relation_customer_p {
        font-size: 29.7px;
        color: #515a71;
    }

    .filter_customer {
        display: flex;
        justify-content: space-between;
    }

    .filter_customer_select {
        width: 170px;
    }

    .filter_customer_search {
        width: 490px;
    }

    .customer_item {
        display: flex;
        align-items: center;
    }

    .customer_item_radio {
        flex-shrink: 0;
        margin-right: 16px;
    }

    .customer_item_t1 {
        font-size: 29.7px;
        font-weight: 700;
        color: #515a71;
        margin-bottom: 8px;
    }

    .customer_item_t2 {
        font-size: 29.7px;
        color: #575f76;
        margin-bottom: 8px;
    }

    .customer_item_t3 {
        font-size: 29.7px;
        color: #858DA1;
    }

    .customer_sex {
        width: 25.46px;
        margin-left: 15px;
    }
</style>